import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    lastname: "",
    message: "",
    error: {},
  };

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  subimtHandler = (e) => {
    e.preventDefault();
    const { t } = this.props;
    const { name, email, subject, lastname, message, error } = this.state;

    if (name === "") error.name = t("contactForm.errors.name");
    if (lastname === "") error.lastname = t("contactForm.errors.lastname");
    if (email === "") error.email = t("contactForm.errors.email");
    else if (!this.validateEmail(email)) error.email = t("contactForm.errors.invalidEmail");
    if (subject === "") error.subject = t("contactForm.errors.subject");
    if (message === "") error.message = t("contactForm.errors.message");

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      this.setState({
        name: "",
        email: "",
        subject: "",
        lastname: "",
        message: "",
        error: {},
      });
      alert(t("contactForm.successMessage"));
    }
  };

  render() {
    const { t } = this.props;
    const { name, email, subject, lastname, message, error } = this.state;

    return (
      <form onSubmit={this.subimtHandler} className="form">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                value={name}
                onChange={this.changeHandler}
                type="text"
                name="name"
                placeholder={t("contactForm.namePlaceholder")}
              />
              <p>{error.name}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                value={lastname}
                onChange={this.changeHandler}
                type="text"
                name="lastname"
                placeholder={t("contactForm.lastnamePlaceholder")}
              />
              <p>{error.lastname}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                value={email}
                onChange={this.changeHandler}
                type="email"
                name="email"
                placeholder={t("contactForm.emailPlaceholder")}
              />
              <p>{error.email}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                value={subject}
                onChange={this.changeHandler}
                type="text"
                name="subject"
                placeholder={t("contactForm.subjectPlaceholder")}
              />
              <p>{error.subject}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              <textarea
                value={message}
                onChange={this.changeHandler}
                name="message"
                placeholder={t("contactForm.messagePlaceholder")}
              ></textarea>
              <p>{error.message}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="theme-btn">
                {t("contactForm.sendButton")}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(ContactForm);
