import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import { useTranslation } from 'react-i18next'; // 1) import

import Trainigprograms2024 from '../../components/TrainingProgram2024';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const TrainingPrograms2024 =() => {

      const { t } = useTranslation(); // 2) call the hook
    
    return(
        <Fragment>
            <Navbar/>
            <PageTitle 
            
                 
            pageTitle={t("training2024PageTitle")}
            pagesub={t("training2024PageSubtitle")}
    
            
            
            /> 
            <Trainigprograms2024/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TrainingPrograms2024;