import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import { useTranslation } from 'react-i18next'; // 1) import
import PageTitle from '../../components/pagetitle'
import Contactpage from '../../components/Contactpage'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const ContactPage =() => {
      const { t } = useTranslation(); // 2) call the hook
    return(
        <Fragment>
            <Navbar/>
            <PageTitle 
            pageTitle={t("contactusPageTitle")}
            pagesub={t("contactusPageSubtitle")}
            /> 
            <Contactpage/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage;

