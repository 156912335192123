import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import { useTranslation } from 'react-i18next'; // 1) import
import Trainigprograms2022 from '../../components/Trainigprograms2022';

import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const TrainingPrograms2022 =() => {
      const { t } = useTranslation(); // 2) call the hook
    
    return(
        <Fragment>
            <Navbar/>
            <PageTitle
                    pageTitle={t("training2022PageTitle")}
                    pagesub={t("training2022PageSubtitle")}
            
           
            
            /> 
            <Trainigprograms2022/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TrainingPrograms2022;