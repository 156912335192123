import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '../src/main-component/App/App';
import reportWebVitals from './reportWebVitals';
import './css/font-awesome.min.css';
import './css/themify-icons.css';
import './css/animate.css';
import './css/flaticon.css';
import './sass/style.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './i18n'; // Import i18n configuration

// Apply scaling globally
document.documentElement.style.zoom = "0.9"; // Set zoom to 70%


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
    
);

// Measure performance
reportWebVitals();
