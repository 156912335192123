import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import prjImg1 from "../../images/clients/bankdhofar.webp";
import prjImg2 from "../../images/clients/civildefense.webp";
import prjImg3 from "../../images/clients/commerce.webp";
import prjImg4 from "../../images/clients/defenseministry.webp";
import prjImg5 from "../../images/clients/education.webp";
import prjImg6 from "../../images/clients/foreign.webp";
import prjImg7 from "../../images/clients/housingandurban.webp";
import prjImg8 from "../../images/clients/madayn.webp";
import prjImg9 from "../../images/clients/media.webp";
import prjImg10 from "../../images/clients/msb.webp";
import prjImg11 from "../../images/clients/msx.webp";
import prjImg12 from "../../images/clients/nama.webp";
import prjImg13 from "../../images/clients/nbo.webp";
import prjImg14 from "../../images/clients/omanguard.webp";
import prjImg15 from "../../images/clients/omanoil.webp";
import prjImg16 from "../../images/clients/omanpolice.webp";
import prjImg17 from "../../images/clients/royalcourt.webp";
import prjImg18 from "../../images/clients/royalopera.webp";
import prjImg19 from "../../images/clients/scientific.webp";
import prjImg20 from "../../images/clients/socialprotectionfund.webp";
import prjImg21 from "../../images/clients/tax.webp";
import prjImg22 from "../../images/clients/tradingandinvestment.webp";
import prjImg23 from "../../images/clients/youth.webp";
import "./AllClients.css";

const CustomArrow = ({ direction, onClick }) => {
    return (
        <button
            className={`custom-arrow custom-arrow-${direction}`}
            onClick={onClick}
        >
            {direction === "next" ? "›" : "‹"}
        </button>
    );
};

const AllClients = () => {
    const { t, i18n } = useTranslation();

    const projects = [
        { pImg: prjImg1, title: t("clientPage.clients.0") },
        { pImg: prjImg3, title: t("clientPage.clients.1") },
        { pImg: prjImg4, title: t("clientPage.clients.2") },
        { pImg: prjImg5, title: t("clientPage.clients.3") },
        { pImg: prjImg6, title: t("clientPage.clients.4") },
        { pImg: prjImg7, title: t("clientPage.clients.5") },
        { pImg: prjImg8, title: t("clientPage.clients.6") },
        { pImg: prjImg11, title: t("clientPage.clients.7") },
        { pImg: prjImg12, title: t("clientPage.clients.8") },
        { pImg: prjImg13, title: t("clientPage.clients.9") },
        { pImg: prjImg14, title: t("clientPage.clients.10") },
        { pImg: prjImg15, title: t("clientPage.clients.11") },
        { pImg: prjImg17, title: t("clientPage.clients.12") },
        { pImg: prjImg19, title: t("clientPage.clients.13") },
        { pImg: prjImg21, title: t("clientPage.clients.14") },
        { pImg: prjImg22, title: t("clientPage.clients.15") },
        { pImg: prjImg23, title: t("clientPage.clients.16") },
        { pImg: prjImg20, title: t("clientPage.clients.17") },
        { pImg: prjImg10, title: t("clientPage.clients.18") },
        { pImg: prjImg9, title: t("clientPage.clients.19") },
        { pImg: prjImg16, title: t("clientPage.clients.20") },
        { pImg: prjImg18, title: t("clientPage.clients.21") },
        { pImg: prjImg2, title: t("clientPage.clients.22") },
    ];

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        draggable: true, // Enable drag functionality
        swipeToSlide: true, // Allow throwing/sliding smoothly
        touchThreshold: 20, // Higher threshold for a smoother throw
        nextArrow: <CustomArrow direction="next" />,
        prevArrow: <CustomArrow direction="prev" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className={`all-clients-section ${i18n.dir()}`}>
            <div className="container">
                <div className="section-title">
                    <h2 className="clients-title">{t("clientPage.title")}</h2>
                    <p className="clients-subtitle">{t("clientPage.subtitle")}</p>
                </div>
                <Slider {...sliderSettings}>
                    {projects.map((project, index) => (
                        <div key={index} className="slider-item">
                            <img
                                src={project.pImg}
                                alt={project.title}
                                className="slider-image"
                            />
                            <h3 className="slider-title">{project.title}</h3>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default AllClients;
