import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import { useTranslation } from 'react-i18next'; // 1) import

import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import MediaGallery from '../../components/MediaPage';
import Scrollbar from '../../components/scrollbar';

const Media = () => {
    const { t } = useTranslation(); // 2) call the hook
  
  return (
    <Fragment>
      <Navbar />
      <PageTitle 
              pageTitle={t("mediaPageTitle")}
              pagesub={t("mediaPageSubtitle")}
      
      />
      <MediaGallery />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default Media;
