import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero2 from '../../components/hero2'

import Support from '../../components/Support'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import WhoWeAre from '../../components/Whoarewe'
import UpcomingTraining from '../../components/trainingplan'
const HomePage2 =() => {
    return(
        <Fragment>
            <Navbar/>
            <Hero2/>
            <WhoWeAre/>
            <UpcomingTraining/>
            <Support/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage2;