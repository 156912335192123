import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next'; // <-- import hook
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import About from '../../components/about';
import FunFact from '../../components/FunFact';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const AboutPage = () => {
  // use the translation hook
  const { t } = useTranslation();

  return (
    <Fragment>
      <Navbar />
      {/* Instead of "About Us" / "Who We Are", call t(...) */}
      <PageTitle
        pageTitle={t("aboutPageTitle")}
        pagesub={t("aboutPageSubtitle")}
      />
      <About abClass={'wpo-about-section-s2'} />
      <FunFact fnClass={'section-padding'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default AboutPage;
