import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./MediaGallery.css";

// Utility function to shuffle and pick unique images
const getRandomUniqueImages = (sourceImages, count) => {
  const shuffled = [...sourceImages].sort(() => Math.random() - 0.5);
  return shuffled.slice(0, count);
};

const MediaGallery = () => {
  const { t } = useTranslation(); // Hook for translation
  const [selectedImg, setSelectedImg] = useState(null);
  const [randomImages, setRandomImages] = useState([]);
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    // Add your image array here when ready
    const images = [
      require("../../images/gallery/image1.webp"),
      require("../../images/gallery/image2.webp"),
      require("../../images/gallery/image3.webp"),
      require("../../images/gallery/image4.webp"),
      require("../../images/gallery/image5.webp"),
      require("../../images/gallery/image6.webp"),
      require("../../images/gallery/image7.webp"),
      require("../../images/gallery/image9.webp"),
      require("../../images/gallery/image10.webp"),
      require("../../images/gallery/image12.webp"),
      require("../../images/gallery/image13.webp"),
      require("../../images/gallery/image14.webp"),
      require("../../images/gallery/image15.webp"),
      require("../../images/gallery/image16.webp"),
      require("../../images/gallery/image17.webp"),
      require("../../images/gallery/image18.webp"),
      require("../../images/gallery/image19.webp"),
      require("../../images/gallery/image20.webp"),
      require("../../images/gallery/image21.webp"),
      require("../../images/gallery/image22.webp"),
      require("../../images/gallery/image23.webp"),
      require("../../images/gallery/image24.webp"),
      require("../../images/gallery/image25.webp"),
      require("../../images/gallery/image26.webp"),
      require("../../images/gallery/image27.webp"),
      require("../../images/gallery/image28.webp"),
      require("../../images/gallery/image29.webp"),
      require("../../images/gallery/image30.webp"),
      require("../../images/gallery/image31.webp"),
      require("../../images/gallery/image32.webp"),
      require("../../images/gallery/image33.webp"),
      require("../../images/gallery/image34.webp"),
      require("../../images/gallery/image35.webp"),
      require("../../images/gallery/image36.webp"),
      require("../../images/gallery/image37.webp"),
      require("../../images/gallery/image38.webp"),
      require("../../images/gallery/image39.webp"),
      require("../../images/gallery/image40.webp"),
      require("../../images/gallery/image41.webp"),
      require("../../images/gallery/image42.webp"),
      require("../../images/gallery/image43.webp"),
      require("../../images/gallery/image44.webp"),
      require("../../images/gallery/image45.webp"),
      require("../../images/gallery/image46.webp"),
      require("../../images/gallery/image47.webp"),
      require("../../images/gallery/image48.webp"),
      require("../../images/gallery/image49.webp"),
      require("../../images/gallery/image50.webp"),
      require("../../images/gallery/image51.webp"),
      require("../../images/gallery/image52.webp"),
      require("../../images/gallery/image53.webp"),
      require("../../images/gallery/image54.webp"),
      require("../../images/gallery/image55.webp"),
      require("../../images/gallery/image56.webp"),
      require("../../images/gallery/image57.webp"),
      require("../../images/gallery/image58.webp"),
      require("../../images/gallery/image59.webp"),
      require("../../images/gallery/image60.webp"),
      require("../../images/gallery/image61.webp"),
      require("../../images/gallery/image62.webp"),
      require("../../images/gallery/image63.webp"),
      require("../../images/gallery/image64.webp"),
      require("../../images/gallery/image65.webp"),
      require("../../images/gallery/image66.webp"),
      require("../../images/gallery/image67.webp"),
      require("../../images/gallery/image68.webp"),
      require("../../images/gallery/image69.webp"),
      require("../../images/gallery/image70.webp"),
      require("../../images/gallery/image71.webp"),
      require("../../images/gallery/image72.webp"),
      require("../../images/gallery/image73.webp"),
      require("../../images/gallery/image74.webp"),
      require("../../images/gallery/image75.webp"),
      require("../../images/gallery/image76.webp"),
      require("../../images/gallery/image77.webp"),
      require("../../images/gallery/image78.webp"),
      require("../../images/gallery/image79.webp"),
      require("../../images/gallery/image80.webp"),
      require("../../images/gallery/image81.webp"),
      require("../../images/gallery/image82.webp"),
      require("../../images/gallery/image83.webp"),
      require("../../images/gallery/image84.webp"),
      require("../../images/gallery/image85.webp"),
      require("../../images/gallery/image86.webp"),
      require("../../images/gallery/image87.webp"),
      require("../../images/gallery/image88.webp"),
      require("../../images/gallery/image89.webp"),
      require("../../images/gallery/image90.webp"),
      require("../../images/gallery/image91.webp"),
      require("../../images/gallery/image92.webp"),
      require("../../images/gallery/image93.webp"),
      require("../../images/gallery/image94.webp"),
      require("../../images/gallery/image95.webp"),
      require("../../images/gallery/image96.webp"),
      require("../../images/gallery/image97.webp"),
      require("../../images/gallery/image98.webp"),
      require("../../images/gallery/image99.webp"),
      require("../../images/gallery/image100.webp"),
      require("../../images/gallery/image101.webp"),
      require("../../images/gallery/image102.webp"),
    ];

    // Shuffle and select 9 unique images
    const uniqueImages = getRandomUniqueImages(images, 9);
    setRandomImages(uniqueImages);
    setLoadedImages(Array(uniqueImages.length).fill(false)); // Initialize loading state
  }, []);

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });
  };

  return (
    <section className="media-gallery-section">
      <div className="container">
        <div className="gallery-header">
          <h2>{t("mediaGallery.title")}</h2>
          <p>{t("mediaGallery.description")}</p>
        </div>
        <div className="gallery-grid">
          {randomImages.map((img, index) => (
            <div
              className="gallery-item"
              key={index}
              onClick={() => setSelectedImg(img)}
            >
              {/* Placeholder for smooth loading */}
              {!loadedImages[index] && <div className="placeholder"></div>}
              <img
                src={img}
                alt={`${t("mediaGallery.imageAlt")} ${index + 1}`}
                className={`gallery-img ${loadedImages[index] ? "loaded" : ""}`}
                loading="lazy"
                onLoad={() => handleImageLoad(index)}
              />
            </div>
          ))}
        </div>
        {selectedImg && (
          <div className="lightbox" onClick={() => setSelectedImg(null)}>
            <span className="close">&times;</span>
            <img
              className="lightbox-content"
              src={selectedImg}
              alt={t("mediaGallery.lightboxAlt")}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default MediaGallery;
