import React, { useState, useEffect, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";

// ====== Image Imports ======
import prjImg1 from "../../images/forums/The annual internal Audit forum/image1.webp";
import prjImg5 from "../../images/forums/The annual internal Audit forum/image2.webp";
import prjImg6 from "../../images/forums/The annual internal Audit forum/image3.webp";
import prjImg7 from "../../images/forums/The annual internal Audit forum/image4.webp";
import prjImg8 from "../../images/forums/The annual internal Audit forum/image5.webp";
import prjImg9 from "../../images/forums/The annual internal Audit forum/image6.webp";

import prjImg2 from "../../images/forums/Annual Forum Creative Leadership in the Context of Good Governance and Institutional Excellence/image1.webp";
import prjImg3 from "../../images/forums/Annual Forum Creative Leadership in the Context of Good Governance and Institutional Excellence/image2.webp";
import prjImg4 from "../../images/forums/Annual Forum Creative Leadership in the Context of Good Governance and Institutional Excellence/image3.webp";
import prjImg10 from "../../images/forums/Annual Forum Creative Leadership in the Context of Good Governance and Institutional Excellence/image4.jpg";

// ====== Preload images ======
const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const ForumsPage = () => {
  const { t, i18n } = useTranslation();

  // EXACT arrow+fade logic, only forum text is i18n
  const projects = [
    {
      images: [prjImg1, prjImg5, prjImg6, prjImg7, prjImg8, prjImg9],
      title: t("forumsPage.forum1.title"),
      tagline: t("forumsPage.forum1.tagline"),
      description: t("forumsPage.forum1.description", { returnObjects: true }),
      bulletPointsHeader: t("forumsPage.forum1.bulletPointsHeader"),
      bulletPoints: t("forumsPage.forum1.bulletPoints", { returnObjects: true }),
      additionalInfo: t("forumsPage.forum1.additionalInfo", { returnObjects: true }),
      location: t("forumsPage.forum1.location"),
      date: t("forumsPage.forum1.date"),
      duration: t("forumsPage.forum1.duration"),
    },
    {
      images: [prjImg2, prjImg3, prjImg4, prjImg10],
      title: t("forumsPage.forum2.title"),
      tagline: t("forumsPage.forum2.tagline"),
      description: t("forumsPage.forum2.description", { returnObjects: true }),
      bulletPointsHeader: t("forumsPage.forum2.bulletPointsHeader"),
      bulletPoints: t("forumsPage.forum2.bulletPoints", { returnObjects: true }),
      additionalInfo: t("forumsPage.forum2.additionalInfo", { returnObjects: true }),
      location: t("forumsPage.forum2.location"),
      date: t("forumsPage.forum2.date"),
      duration: t("forumsPage.forum2.duration"),
    },
  ];

  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const currentProject = projects[currentProjectIndex];

  useEffect(() => {
    const allImages = projects.flatMap((p) => p.images || []);
    preloadImages(allImages);
  }, [projects]);

  // Fade out, then switch image
  const handlePreviousImage = useCallback(() => {
    if (!isFading) {
      setIsFading(true);
      setTimeout(() => {
        setMainImageIndex((prev) =>
          prev === 0 ? currentProject.images.length - 1 : prev - 1
        );
        setIsFading(false);
      }, 250);
    }
  }, [isFading, currentProject.images]);

  const handleNextImage = useCallback(() => {
    if (!isFading) {
      setIsFading(true);
      setTimeout(() => {
        setMainImageIndex(
          (prev) => (prev + 1) % currentProject.images.length
        );
        setIsFading(false);
      }, 250);
    }
  }, [isFading, currentProject.images]);

  // Switch entire forum
  const handlePreviousProject = () => {
    setCurrentProjectIndex((prev) =>
      prev === 0 ? projects.length - 1 : prev - 1
    );
    setMainImageIndex(0);
  };

  const handleNextProject = () => {
    setCurrentProjectIndex((prev) =>
      (prev + 1) % projects.length
    );
    setMainImageIndex(0);
  };

  // RTL only for text content
  const isRTL = i18n.language === "ar";
  const textDirectionStyle = {
    direction: isRTL ? "rtl" : "ltr",
    textAlign: isRTL ? "right" : "left",
  };

  return (
    <section className="wpo-project-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="wpo-project-single-wrap">
              <div className="wpo-project-single-content">
                {/* Image Slider */}
                <div className="main-image-container">
                  {currentProject.images && currentProject.images.length > 0 ? (
                    <>
                      <button
                        className="slider-arrow left-arrow"
                        onClick={handlePreviousImage}
                      >
                        &larr;
                      </button>
                      <img
                        src={currentProject.images[mainImageIndex]}
                        alt={`Forum Slide ${mainImageIndex + 1}`}
                        className={`main-slider-image ${
                          isFading ? "fade-out" : "fade-in"
                        }`}
                      />
                      <button
                        className="slider-arrow right-arrow"
                        onClick={handleNextImage}
                      >
                        &rarr;
                      </button>
                    </>
                  ) : (
                    <p style={{ textAlign: "center", padding: "20px", color: "#555" }}>
                      No images available.
                    </p>
                  )}
                </div>

                {/* Forum Details */}
                <div className="wpo-project-single-content-des">
                  <div className="wpo-project-single-content-des-wrap">
                    <div
                      className="wpo-project-single-content-des-left"
                      style={textDirectionStyle}
                    >
                      <h2>{currentProject.title}</h2>
                      {currentProject.tagline && (
                        <p style={{ fontStyle: "italic", marginBottom: "20px" }}>
                          {currentProject.tagline}
                        </p>
                      )}

                      {currentProject.description.map((para, idx) => (
                        <p key={idx}>{para}</p>
                      ))}

                      {currentProject.bulletPointsHeader && (
                        <h3
                          style={{
                            marginBottom: "10px",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                          }}
                        >
                          {currentProject.bulletPointsHeader}
                        </h3>
                      )}
                      {currentProject.bulletPoints &&
                        currentProject.bulletPoints.length > 0 && (
                          <ul
                            style={{
                              paddingInlineStart: "1.5rem",
                              listStyleType: "disc",
                            }}
                          >
                            {currentProject.bulletPoints.map((point, i) => (
                              <li
                                key={i}
                                style={{ marginBottom: "8px", lineHeight: "1.6" }}
                              >
                                {point}
                              </li>
                            ))}
                          </ul>
                        )}
                      {currentProject.additionalInfo &&
                        currentProject.additionalInfo.map((info, i) => (
                          <p key={i}>{info}</p>
                        ))}
                    </div>

                    <div
                      className="wpo-project-single-content-des-right"
                      style={textDirectionStyle}
                    >
                      <ul>
                        <li>
                          {t("locationLabel")}: <span>{currentProject.location}</span>
                        </li>
                        <li>
                          {t("dateLabel")}: <span>{currentProject.date}</span>
                        </li>
                        <li>
                          {t("durationLabel")}: <span>{currentProject.duration}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Navigation Buttons EXACT like 2024 */}
              <div className="more-posts">
                <button
                  onClick={handlePreviousProject}
                  className="arrow-btn prev-btn"
                >
                  &larr; {t("previousLabel")}
                </button>
                <button
                  onClick={handleNextProject}
                  className="arrow-btn next-btn"
                >
                  {t("nextLabel")} &rarr;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Inline styling identical to 2024 */}
      <style>{`
        .main-image-container {
          position: relative;
          width: 100%;
          max-height: 500px;
          overflow: hidden;
          border-radius: 15px;
        }

        .main-slider-image {
          width: 100%;
          height: 500px;
          object-fit: cover;
          border-radius: 15px;
          opacity: 1;
          transition: opacity 0.25s ease-in-out;
        }

        .main-slider-image.fade-out {
          opacity: 0;
        }

        .main-slider-image.fade-in {
          opacity: 1;
        }

        .slider-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
          font-size: 18px;
          border-radius: 5px;
        }
        .slider-arrow.left-arrow {
          left: 10px;
        }
        .slider-arrow.right-arrow {
          right: 10px;
        }
        .slider-arrow:hover {
          background: rgba(0, 0, 0, 0.8);
        }

        .arrow-btn {
          background: linear-gradient(145deg, #003366, #004080);
          color: white;
          border: none;
          padding: 12px 24px;
          border-radius: 25px;
          font-size: 16px;
          cursor: pointer;
        }

        .more-posts {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
        }

        .wpo-project-single-content-des-wrap {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
        .wpo-project-single-content-des-left {
          flex: 0 0 70%;
          padding-right: 20px;
        }
        .wpo-project-single-content-des-right {
          flex: 0 0 30%;
        }
        .wpo-project-single-content-des-right ul {
          list-style: none;
          padding: 0;
        }
        .wpo-project-single-content-des-right li {
          margin-bottom: 10px;
          font-size: 1rem;
          color: #555;
        }
        .wpo-project-single-content-des-right span {
          font-weight: bold;
        }
      `}</style>
    </section>
  );
};

export default memo(ForumsPage);
