import React from "react";
import { useTranslation } from "react-i18next";

const ThirdServiceSection = () => {
  const { t, i18n } = useTranslation();

  // We reference e.g. t("comingSoon.heading") & t("comingSoon.paragraph")
  // so these get pulled from en.json / ar.json
  return (
    <section className="coming-soon-section">
      <div className="content">
        <h1>{t("comingSoon.heading")}</h1>
        <p>{t("comingSoon.paragraph")}</p>
      </div>

      <style>{`
        .coming-soon-section {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          color: #333;
          text-align: center;
          padding: 50px 0;
        }

        .content {
          max-width: 600px;
          text-align: center;
        }

        h1 {
          font-size: 3rem;
          margin: 0 0 20px;
          color: #004080;
        }

        p {
          font-size: 1.2rem;
          color: #555;
        }
      `}</style>
    </section>
  );
};

export default ThirdServiceSection;
