import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Error = () => {
  const { t } = useTranslation();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="error-404-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="content clearfix">
              <div className="error"></div>
              <div className="error-message">
                <h3>{t("errorPage.title")}</h3>
                <p>{t("errorPage.description")}</p>
                <Link onClick={ClickHandler} to="/" className="theme-btn">
                  <i className="fa fa-angle-double-left"></i> {t("errorPage.backToHome")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
