import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withTranslation } from "react-i18next"; // Import translation HOC

class Testimonial extends Component {
    render() {
        const { t } = this.props; // Translation function from i18next

        var settings = {
            dots: false, // Disable dots for smoother rendering
            arrows: true, // Keep navigation arrows
            infinite: true, // Enable smooth looping
            speed: 800, // Moderate speed for smooth transitions
            cssEase: "ease-in-out", // Smoother easing curve
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000, // Balanced autoplay speed
            swipeToSlide: true, // Enable direct swipe interactions
            touchThreshold: 15, // Adjust touch sensitivity for smoother swipes
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        const testimonial = [
            {
                Des: t("testimonial.0.des"),
                Title: t("testimonial.0.title"),
                Sub: t("testimonial.0.sub"),
            },
            {
                Des: t("testimonial.1.des"),
                Title: t("testimonial.1.title"),
                Sub: t("testimonial.1.sub"),
            },
            {
                Des: t("testimonial.2.des"),
                Title: t("testimonial.2.title"),
                Sub: t("testimonial.2.sub"),
            },
            {
                Des: t("testimonial.3.des"),
                Title: t("testimonial.3.title"),
                Sub: t("testimonial.3.sub"),
            },
            {
                Des: t("testimonial.4.des"),
                Title: t("testimonial.4.title"),
                Sub: t("testimonial.4.sub"),
            },
            {
                Des: t("testimonial.5.des"),
                Title: t("testimonial.5.title"),
                Sub: t("testimonial.5.sub"),
            },
            {
                Des: t("testimonial.6.des"),
                Title: t("testimonial.6.title"),
                Sub: t("testimonial.6.sub"),
            },
            {
                Des: t("testimonial.7.des"),
                Title: t("testimonial.7.title"),
                Sub: t("testimonial.7.sub"),
            },
        ];

        return (
            <section className={`wpo-testimonial-section section-padding ${this.props.tClass}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="wpo-testimonial-title">
                                <h2>
                                    <i className="fi flaticon-left-quote"></i>
                                </h2>
                                <h2>{t("testimonial.title")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="wpo-testimonial-items wpo-testimonial-slider owl-carousel">
                        <Slider {...settings}>
                            {testimonial.map((tesmnl, tsm) => (
                                <div className="wpo-testimonial-item" key={tsm}>
                                    <div className="wpo-testimonial-text">
                                        <p>{tesmnl.Des}</p>
                                        <div className="wpo-testimonial-text-btm">
                                            <h3>{tesmnl.Title}</h3>
                                            <span>{tesmnl.Sub}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation()(Testimonial);
