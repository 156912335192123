import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // 1) import useTranslation

const PageTitle = (props) => {
  const { t } = useTranslation(); // 2) call the hook

  return (
    <div className="wpo-breadcumb-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="wpo-breadcumb-wrap">
              {/* 
                Instead of {props.pageTitle}, call t() if you want 
                to localize or if props.pageTitle is already 
                something like t("someKey").
              */}
              <h2>{props.pageTitle}</h2>

              <ul>
                {/* 
                  "Home" replaced with t("homeLink") 
                  so it fetches the translation from en/ar. 
                */}
                <li>
                  <Link to="/home">{t("homeLink")}</Link>
                </li>

                {/* 
                  pagesub is also passed from parent; 
                  you can localize it similarly if you prefer. 
                */}
                <li>
                  <span>{props.pagesub}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageTitle;
