import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import { useTranslation } from 'react-i18next'; // 1) import

import Scrollbar from '../../components/scrollbar'
import Testimonial from '../../components/Testimonial'



const TestimonialPage =() => {
      const { t } = useTranslation(); // 2) call the hook
    
    return(
        <Fragment>
            <Navbar/>
            <PageTitle
            
            
            pageTitle={t("testimonialsPageTitle")}
            pagesub={t("testimonialsPageSubtitle")}            
            
            
            /> 
            <Testimonial tClass="style-2"/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TestimonialPage;
