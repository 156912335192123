import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next'; // 1) import
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import AllClients from '../../components/Clientpage'; // Correct import

const Clientspage = () => {
  const { t } = useTranslation(); // 2) call the hook

  return (
    <Fragment>
      <Navbar />
      {/* pass t(...) values to PageTitle */}
      <PageTitle
        pageTitle={t("clientsPageTitle")}
        pagesub={t("clientsPageSubtitle")}
      />
      <AllClients />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default Clientspage;

