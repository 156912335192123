import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Logo from "../../images/logo.webp";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";
import trainingPlan2023 from "../../files/Mafaz_Training_plan_2023.pdf";
import "./Header.css"; // Ensure this file contains updated CSS

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,
    };
  }

  handleScroll = () => {
    const isScrolled = window.scrollY > 80;
    if (isScrolled !== this.state.isScrolled) {
      this.setState({ isScrolled });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  ClickHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  preventClick = (e) => {
    e.preventDefault();
  };

  changeLanguage = (lang) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
  };

  render() {
    const { t } = this.props;
    const { isScrolled } = this.state;

    return (
      <>
        <header
          id="header"
          className={`wpo-site-header ${isScrolled ? "scrolled" : ""}`}
        >
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="full-width-container">
              <div className="row align-items-center no-gutters">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <Link
                      onClick={this.ClickHandler}
                      className="navbar-brand"
                      to="/home"
                    >
                      <img src={Logo} alt="Logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7 col-md-1 col-1">
                  <div
                    id="navbar"
                    className="collapse navbar-collapse navigation-holder"
                  >
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li>
                        <Link onClick={this.ClickHandler} to="/">
                          {t("home")}
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.ClickHandler} to="/about">
                          {t("about")}
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link
                          to="#"
                          onClick={this.preventClick}
                          className="nav-link"
                        >
                          {t("services")}
                        </Link>
                        <ul className="sub-menu">
                          <li className="menu-item-has-children">
                            <Link
                              to="#"
                              onClick={this.preventClick}
                              className="nav-link"
                            >
                              {t("trainingPrograms")}
                            </Link>
                            <ul className="sub-sub-menu">
                              <li>
                                <Link
                                  onClick={this.ClickHandler}
                                  to="/trainingprograms2024"
                                >
                                  {t("trainingPrograms2024Label")                                  }
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={this.ClickHandler}
                                  to="/trainingprograms2023"
                                >
                                  {t("trainingPrograms2023Label")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={this.ClickHandler}
                                  to="/trainingprograms2022"
                                >
                                  {t("trainingPrograms2022Label")}
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link onClick={this.ClickHandler} to="/forums">
                              {t("forums")}
                            </Link>
                          </li>
                          <li>
                            <Link onClick={this.ClickHandler} to="/conferences">
                              {t("conferences")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link onClick={this.ClickHandler} to="/media">
                          {t("media")}
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link
                          onClick={this.ClickHandler}
                          to="/clients"
                          aria-expanded="false"
                        >
                          {t("ourClients")}
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link onClick={this.ClickHandler} to="/clients">
                              {t("clients")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.ClickHandler}
                              to="/testimonials"
                            >
                              {t("testimonials")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link onClick={this.ClickHandler} to="/trainingplan">
                          {t("trainingPlans")}
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.ClickHandler} to="/contact">
                          {t("contactUs")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-2 text-end">
                  <button
                    className="btn btn-outline-primary mx-1"
                    onClick={() => this.changeLanguage("en")}
                  >
                    English
                  </button>
                  <button
                    className="btn btn-outline-primary mx-1"
                    onClick={() => this.changeLanguage("ar")}
                  >
                    العربية
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="header-spacer"></div>
      </>
    );
  }
}

export default withTranslation()(Header);
