import React from "react";
import { useTranslation } from "react-i18next";
import trainingCover from "../../images/training.webp";
import trainingPlanPDF from "../../files/Mafaz_Training_plan_2024.pdf";
import "./UpcomingTraining.css";

const UpcomingTraining = () => {
  const { t } = useTranslation();

  const programs = [
    {
      title: t("upcomingTraining.programs.0.title"),
      location: t("upcomingTraining.programs.0.location"),
      date: t("upcomingTraining.programs.0.date"),
    },
    {
      title: t("upcomingTraining.programs.1.title"),
      location: t("upcomingTraining.programs.1.location"),
      date: t("upcomingTraining.programs.1.date"),
    },
    {
      title: t("upcomingTraining.programs.2.title"),
      location: t("upcomingTraining.programs.2.location"),
      date: t("upcomingTraining.programs.2.date"),
    },
    {
      title: t("upcomingTraining.programs.3.title"),
      location: t("upcomingTraining.programs.3.location"),
      date: t("upcomingTraining.programs.3.date"),
    },
    {
      title: t("upcomingTraining.programs.4.title"),
      location: t("upcomingTraining.programs.4.location"),
      date: t("upcomingTraining.programs.4.date"),
    }
  ];

  return (
    <section className="training-section">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="training-cover">
              <a href={trainingPlanPDF} download="Mafaz_Training_plan_2024">
                <img src={trainingCover} alt="Training Plan 2024" />
                <h2>{t("upcomingTraining.getPlan")}</h2>
              </a>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="training-programs">
              <h3>{t("upcomingTraining.title")}</h3>
              <ul className="program-list">
                {programs.map((program, index) => (
                  <li key={index} className="program-item">
                    <span className="program-title">{program.title}</span>
                    <span className="program-location">{program.location}</span>
                    <span className="program-date">{program.date}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingTraining;
