import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// Styled components
const WhoWeAreSection = styled.section`
  padding: 80px 20px;
  background-color: #f4f7fc;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 3.5rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-size: 1.4rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto 20px auto;
  line-height: 1.8;
`;

const WhoWeAre = () => {
  const { t } = useTranslation();

  return (
    <WhoWeAreSection>
      <Title>{t("whoWeAre.title")}</Title>
      <Description>{t("whoWeAre.description")}</Description>
    </WhoWeAreSection>
  );
};

export default WhoWeAre;
