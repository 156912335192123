import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Add translation functionality

// Styled components for layout
const Section = styled.section`
  padding: 100px 20px;
  background-color: #f4f7fc;
  font-family: "Arial", sans-serif;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const SubTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const MissionVisionGoalsSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 100px 0;
  gap: 40px;
`;

const MissionVisionGoalsCard = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 320px;
  transition: transform 0.3s ease-in-out;
  text-align: left;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
`;

const CardTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 600;
  color: #003366;
  margin-bottom: 20px;
  text-transform: capitalize;
`;

const CardDescription = styled.div`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.8;

  ul {
    list-style-type: disc;
    padding-left: ${({ lang }) => (lang === "ar" ? "0" : "20px")};
    padding-right: ${({ lang }) => (lang === "ar" ? "20px" : "0")};
    margin-top: 15px;
    text-align: ${({ lang }) => (lang === "ar" ? "right" : "left")};
    direction: ${({ lang }) => (lang === "ar" ? "rtl" : "ltr")};
  }

  li {
    margin-bottom: 12px;
  }
`;

const TeamSection = styled.div`
  margin-top: 80px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const TeamTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 30px;
`;

const TeamDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
`;

const TrainersSection = styled.div`
  margin-top: 60px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const TrainersTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 30px;
`;

const TrainerDescription = styled.div`
  font-size: 1.2rem;
  color: #666;
  line-height: 1.8;

  ul {
    list-style-type: disc;
    padding-left: ${({ lang }) => (lang === "ar" ? "0" : "20px")};
    padding-right: ${({ lang }) => (lang === "ar" ? "20px" : "0")};
    text-align: ${({ lang }) => (lang === "ar" ? "right" : "left")};
    direction: ${({ lang }) => (lang === "ar" ? "rtl" : "ltr")};
  }

  li {
    margin-bottom: 12px;
  }
`;

const CallToAction = styled.div`
  background-color: #e9ecf5;
  color: #003366;
  padding: 80px 20px;
  text-align: center;
  margin-top: 60px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const CallToActionTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 20px;
`;

const CallToActionText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #003366;
  color: #fff;
  padding: 15px 30px;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 6px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #002244;
  }
`;

const AboutPage = () => {
  const { t, i18n } = useTranslation(); // Translation hook

  return (
    <Section>
      <Container>
        <SubTitle>{t("aboutPage.subtitle")}</SubTitle>

        {/* Mission, Vision, Goals */}
        <MissionVisionGoalsSection>
          <MissionVisionGoalsCard>
            <CardTitle>{t("aboutPage.mission.title")}</CardTitle>
            <CardDescription>{t("aboutPage.mission.description")}</CardDescription>
          </MissionVisionGoalsCard>
          <MissionVisionGoalsCard>
            <CardTitle>{t("aboutPage.vision.title")}</CardTitle>
            <CardDescription>{t("aboutPage.vision.description")}</CardDescription>
          </MissionVisionGoalsCard>
          <MissionVisionGoalsCard>
            <CardTitle>{t("aboutPage.goals.title")}</CardTitle>
            <CardDescription lang={i18n.language}>
              <ul>
                {t("aboutPage.goals.list", { returnObjects: true }).map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </CardDescription>
          </MissionVisionGoalsCard>
        </MissionVisionGoalsSection>

        {/* Team Section */}
        <TeamSection>
          <TeamTitle>{t("aboutPage.team.title")}</TeamTitle>
          <TeamDescription>{t("aboutPage.team.description")}</TeamDescription>
        </TeamSection>

        {/* Trainers Section */}
        <TrainersSection>
          <TrainersTitle>{t("aboutPage.trainers.title")}</TrainersTitle>
          <TrainerDescription lang={i18n.language}>
            <ul>
              {t("aboutPage.trainers.list", { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </TrainerDescription>
        </TrainersSection>

        {/* Call to Action */}
        <CallToAction>
          <CallToActionTitle>{t("aboutPage.callToAction.title")}</CallToActionTitle>
          <CallToActionText>{t("aboutPage.callToAction.description")}</CallToActionText>
          <Button to="/contact">{t("aboutPage.callToAction.button")}</Button>
        </CallToAction>
      </Container>
    </Section>
  );
};

export default AboutPage;
