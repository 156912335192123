import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import { useTranslation } from 'react-i18next'; // 1) import

import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import ForumsPagee from '../../components/Forums-component'


const Forumspage =() => {
      const { t } = useTranslation(); // 2) call the hook
    
    return(
        <Fragment>
            <Navbar/>
            <PageTitle 
            
            pageTitle={t("forumsPageTitle")}
            pagesub={t("forumsPageSubtitle")}
            
            
            
            
            /> 
            <ForumsPagee />
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Forumspage;


