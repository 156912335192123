import React from "react";
import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-support-section mb-5">
      <div className="container">
        <div className="wpo-support-wrapper">
          <div className="wpo-support-text">
            <h2>{t("support.title")}</h2>
          </div>
          <div className="wpo-support-btn">
            <a onClick={ClickHandler} href="/contact">
              {t("support.button")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Support;
