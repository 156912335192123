import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import { useTranslation } from 'react-i18next'; // 1) import
import UpcomingTraining from '../../components/trainingplan';
import Scrollbar from '../../components/scrollbar'


const TrainingPlan =() => {
      const { t } = useTranslation(); // 2) call the hook
    
    return(
        <Fragment>
            <Navbar/>
            <PageTitle 
            
            
            pageTitle={t("trainingplanPageTitle")}
            pagesub={t("trainingplanPageSubtitle")}
            
            /> 
            <UpcomingTraining/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TrainingPlan;

